
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { dateTimeFormat } from '~/utils/DateTimeUtils'
import { LocalStorageFiltersStore } from '~/store';

@Component({})
export default class InstagramOrdersTable extends Vue {
  @Prop({ default: () => { return null }, required: false }) readonly data!: any
  @Prop({ default: () => { return null }, required: false }) readonly loading!: any
  @Prop({ default: () => { return 1 }, required: false }) readonly currentPage!: number
  @Prop({ default: () => { return 100 }, required: false }) readonly currentPerPage!: number
  @Prop({ default: () => { return false }, required: false }) readonly isPerUser!: number

  tableItems: any = [];
  totalData: number = 0;
  page: number = 1;
  perPage: number = 100;
  perPageOptions = [15, 30, 50, 100, 300, 500];
  options: any = {};
  headers = [
    { text: this.$t('id'), value: 'id' },
    { text: this.$t('created'), value: 'created' },
    { text: this.$t('service'), value: 'order_type' },
    { text: this.$t('quantity'), value: 'quantity' },
    { text: this.$t('price'), value: 'price' },
    { text: this.$t('dripFeed'), value: 'drip_feed' },
    { text: this.$t('details'), value: 'service_details', sortable: false },
    { text: this.$t('status'), value: 'status', align: 'center' },
  ];

  mounted() {
    const user = this.$auth?.user || {};
    if(user?.is_admin) {
      if(!this.isPerUser) {
        this.headers.push({ text: this.$t('buyer'), value: 'user' });
      }
      this.headers.push({ text: '', value: 'actions' });
      this.headers.splice(1, 0, { text: this.$t('instaOrderId'), value: 'instabot_server_id' });
    }

    this.page = this.currentPage;
    this.perPage = this.currentPerPage;

    this.getData();
    this.setParams();
  }

  @Watch('options', { deep: true })
  query() {
    if(!this.freezeWatchFlag) {
      this.setParams();
    }
  }

  @Watch('currentPage')
  checkPage() {
    this.page = this.currentPage;
  }

  @Watch('currentPerPage')
  checkPerPage() {
    this.perPage = this.currentPerPage;
  }

  @Watch('loading')
  checkLoading() {
    if(this.loading) {
      this.totalData = 0;
      this.tableItems = [];
    }
  }

  @Watch('data')
  getCustomData() {
    this.getData();
  }

  handlePageChange(page: number) {
    if (page !== this.page) {
      this.page = page;
      this.setParams();
    }
  }

  handlePerPageChange(perPage: number) {
    this.page = 1;
    this.perPage = perPage;
    this.setParams();
  }

  setParams() {
    this.tableItems = [];
    this.$emit('refresh', { page: this.page, perPage: this.perPage, options: this.options });
  }

  getData() {
    if(this.data) {
      this.totalData = this.data.count ? this.data.count : 0;
      this.tableItems = this.data.count > 0 ? this.data.results : [];

      const count = this.data.count/this.perPage;
      const pageCount = count == Math.floor(count) ? count : (Math.floor(count) + 1);
      this.page = this.page > pageCount ? 1 : this.page;
    }
  }

  formatDateTime(data:any) {
    return dateTimeFormat(this, data);
  }

  writeToFile(item:any) {
    if(item.comments_text) {
      const content = JSON.parse(item.comments_text).join('\n');
      const blob = new Blob([content], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'CommentsList' + item.id + this.$dayjs(item.created).format('YYYYMMDDHHMM') + '.txt';
      link.click();
    }
  }

  checkIfHasDownloadBtn(data:any) {
    if(data) {
      const check = JSON.parse(data);
      return check.length > 0 ? true : false;
    } else {
      return false;
    }
  }

  convertSecondsToDHMS(sec:any) {
    const days = Math.floor(sec / (3600 * 24));
    const hours = Math.floor((sec % (3600 * 24)) / 3600);
    const minutes = Math.floor((sec % 3600) / 60);
    const seconds = sec % 60;
    let time = "";
    
    if(days > 0) {
      time += days + " " + this.$t('days');
      if(hours > 0 || minutes > 0 || seconds > 0) {
        time += ", ";
      }
    }
    if(hours > 0) {
      time += hours + " " + this.$t('hours');
      if(minutes > 0 || seconds > 0) {
        time += ", ";
      }
    }
    if(minutes > 0) {
      time +=  minutes + " " + this.$t('minutes');
      if(seconds > 0) {
        time += ", ";
      }
    }
    if(seconds > 0) {
      time += seconds + " " + this.$t('seconds');
    }

    return time;
  }

  formatId(id:any) {
    if (parseInt(id) < 10) {
      return '000' + id;
    } else if (parseInt(id) < 100) {
      return '00' + id;
    } else if (parseInt(id) < 1000) {
      return '0' + id;
    } else {
      return String(id);
    }
  }

  getName(user:any) {
    if (!user) return "";
    const { first_name, last_name, email } = user;
    const fullName = [first_name, last_name].filter(Boolean).join(" ");

    return fullName || email || "";
  }

  get freezeWatchFlag() {
    return LocalStorageFiltersStore.getFreeWatchFlag;
  }
}
