var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          !_vm.noAddBtn
            ? _c(
                "v-col",
                {
                  staticClass: "pb-0",
                  attrs: { cols: "12", md: "3", sm: "6" },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "custom-cards bordered",
                      attrs: {
                        height: "100%",
                        ripple: false,
                        to: "/dashboard/instagram/orders/add",
                      },
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "d-flex align-center justify-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c("v-icon", {}, [
                                _vm._v("mdi-plus-circle-outline"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "text-16 font-weight-medium mt-3",
                                },
                                [_vm._v(_vm._s(_vm.$t("addOrder")))]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.data, function (item) {
            return [
              _c(
                "v-col",
                {
                  staticClass: "pb-0",
                  attrs: { cols: "12", md: "3", sm: "6" },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "custom-cards bordered",
                      attrs: { height: "100%" },
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "px-4 pt-3 pb-2" },
                        [
                          _c("div", { staticClass: "d-flex" }, [
                            _c("div", { staticClass: "w-100" }, [
                              _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "h1",
                                  {
                                    staticClass:
                                      "text-14 username text-capitalize",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.order_type.replace(/_/g, " "))
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "ml-auto mr-1 font-weight-medium",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.formatDateTime(item.created))
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "d-flex align-center mt-1" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex mt-1" },
                                    [
                                      _c(
                                        "instagram-orders-order-types-selection",
                                        {
                                          attrs: {
                                            item: {
                                              value: item.order_type,
                                              count: item.quantity,
                                            },
                                            size: 16,
                                            "custom-class": "mr-2",
                                          },
                                        }
                                      ),
                                      _vm._v(" "),
                                      _vm.checkIfHasDownloadBtn(
                                        item.comments_text
                                      )
                                        ? [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                staticStyle: {
                                                  "margin-top": "2px",
                                                },
                                                attrs: { small: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.writeToFile(item)
                                                  },
                                                },
                                              },
                                              [_vm._v("mdi-download")]
                                            ),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.price
                                        ? [
                                            _c("v-divider", {
                                              staticClass: "mx-3",
                                              attrs: { vertical: "" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-weight-medium text-black",
                                              },
                                              [_vm._v(_vm._s(item.price))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "text-brand ml-1",
                                                attrs: { size: "12" },
                                              },
                                              [_vm._v("mdi-tag-outline")]
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "ml-3 w-100" },
                                    [
                                      item.status == "processing" ||
                                      item.status == "cancelled" ||
                                      item.status == "refunded"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "custom-status ml-auto w-100",
                                                class: item.status,
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-capitalize",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.status) +
                                                        " - "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.task_execution_count +
                                                        "/" +
                                                        item.quantity
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        : [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "custom-status ml-auto w-100",
                                                class: item.status
                                                  ? item.status
                                                  : "created",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-capitalize",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.status
                                                          ? item.status
                                                          : _vm.$t("created")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "mt-2" }),
                          _vm._v(" "),
                          item.drip_feed
                            ? _c(
                                "div",
                                { staticClass: "d-flex mt-2" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "font-weight-medium" },
                                    [_vm._v(_vm._s(_vm.$t("dripFeed")) + ": ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "text-brand",
                                      attrs: {
                                        small: "",
                                        color: "blue lighten-2",
                                      },
                                    },
                                    [_vm._v("mdi-water")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-medium text-black",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.convertSecondsToDHMS(
                                            item.drip_feed
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-2 d-flex" }, [
                            _c("div", [
                              _c(
                                "label",
                                { staticClass: "font-weight-medium" },
                                [_vm._v(_vm._s(_vm.$t("id")) + ":")]
                              ),
                              _vm._v(" "),
                              _c("div", [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.formatId(item.id))),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.$auth &&
                            _vm.$auth.user &&
                            _vm.$auth.user.is_admin
                              ? _c("div", { staticClass: "ml-auto mr-5" }, [
                                  _c(
                                    "label",
                                    { staticClass: "font-weight-medium" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("instaOrderId")) + ":"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("span", [
                                      _vm._v(_vm._s(item.instabot_server_id)),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "label",
                                { staticClass: "font-weight-medium" },
                                [_vm._v(_vm._s(_vm.$t("details")) + ":")]
                              ),
                              _vm._v(" "),
                              item.order_type == "followers"
                                ? [
                                    _c("span", [
                                      _vm._v("@" + _vm._s(item.ig_username)),
                                    ]),
                                  ]
                                : [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "overflow-wrap": "break-word",
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.target_url)),
                                        ]),
                                      ]
                                    ),
                                  ],
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.$auth && _vm.$auth.user && _vm.$auth.user.is_admin
                            ? [
                                _c("v-divider", { staticClass: "mt-2" }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-end mt-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "line-height-normal mr-auto",
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "font-weight-medium" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("buyer")) + ":"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "nuxt-link",
                                          {
                                            attrs: {
                                              to: `/dashboard/admin/users/${item.user.id}/view`,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text-brand" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.getName(item.user))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("ActionButton", {
                                      attrs: {
                                        disabled:
                                          item.status == "refunded" ||
                                          item.status == "fulfilled",
                                        data: {
                                          icon:
                                            item.status == "cancelled"
                                              ? "mdi-cash-refund"
                                              : "mdi-cancel",
                                          icon_color: "text-brand-error",
                                          label:
                                            item.status == "cancelled"
                                              ? _vm.$t("refund")
                                              : _vm.$t("cancel"),
                                        },
                                      },
                                      on: {
                                        clicked: function ($event) {
                                          return _vm.$emit("cancel-event", item)
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("ActionButton", {
                                      attrs: {
                                        disabled:
                                          item.status != "created" &&
                                          item.status != "processing",
                                        data: {
                                          icon: "mdi-pencil-outline",
                                          icon_color: "warning--text",
                                          label: "Edit",
                                        },
                                      },
                                      on: {
                                        clicked: function ($event) {
                                          return _vm.$emit("edit-event", {
                                            id: item.id,
                                            target_url: item.target_url,
                                            drip_feed: item.drip_feed,
                                            order_type: item.order_type,
                                          })
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }