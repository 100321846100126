export { default as NuxtLogo } from '../../components/NuxtLogo.vue'
export { default as VuetifyLogo } from '../../components/VuetifyLogo.vue'
export { default as ActionButton } from '../../components/action-button.vue'
export { default as AlertError } from '../../components/alert-error.vue'
export { default as AlertSuccess } from '../../components/alert-success.vue'
export { default as ColorPickerModal } from '../../components/color-picker-modal.vue'
export { default as CookieConsent } from '../../components/cookie-consent.vue'
export { default as CustomDateRangePicker } from '../../components/custom-date-range-picker.vue'
export { default as LanguageSelection } from '../../components/language-selection.vue'
export { default as NoSubscription } from '../../components/no-subscription.vue'
export { default as Pagination } from '../../components/pagination.vue'
export { default as SnackbarError } from '../../components/snackbar-error.vue'
export { default as SnackbarSuccess } from '../../components/snackbar-success.vue'
export { default as SortDropdown } from '../../components/sort-dropdown.vue'
export { default as StandardCardList } from '../../components/standard-card-list.vue'
export { default as TextFileParser } from '../../components/text-file-parser.vue'
export { default as TipTapEditor } from '../../components/tip-tap-editor.vue'
export { default as UsersCreditsDropdown } from '../../components/users-credits-dropdown.vue'
export { default as UsersDropdown } from '../../components/users-dropdown.vue'
export { default as ChartsBarChart } from '../../components/charts/BarChart.js'
export { default as ChartsD3Chart } from '../../components/charts/D3Chart.vue'
export { default as ChartsLineChart } from '../../components/charts/LineChart.js'
export { default as InstagramPostPreview } from '../../components/instagram/post-preview.vue'
export { default as AdminAccounts } from '../../components/admin/accounts.vue'
export { default as AdminMobileProxy } from '../../components/admin/mobile-proxy.vue'
export { default as AdminOrders } from '../../components/admin/orders.vue'
export { default as AdminTransactions } from '../../components/admin/transactions.vue'
export { default as LandingBlackhatForumSection } from '../../components/landing/blackhat-forum-section.vue'
export { default as LandingBoostSection } from '../../components/landing/boost-section.vue'
export { default as LandingCommittedSection } from '../../components/landing/committed-section.vue'
export { default as LandingFaqSection } from '../../components/landing/faq-section.vue'
export { default as LandingFeaturesSection } from '../../components/landing/features-section.vue'
export { default as LandingFollowersCompute } from '../../components/landing/followers-compute.vue'
export { default as LandingFooter } from '../../components/landing/footer.vue'
export { default as LandingIgFlatrateSection } from '../../components/landing/ig-flatrate-section.vue'
export { default as LandingOverviewSection } from '../../components/landing/overview-section.vue'
export { default as LandingProcessSteps } from '../../components/landing/process-steps.vue'
export { default as LandingSendUsMessageSection } from '../../components/landing/send-us-message-section.vue'
export { default as LandingServicesSection } from '../../components/landing/services-section.vue'
export { default as LandingSmmPanelsSection } from '../../components/landing/smm-panels-section.vue'
export { default as LandingStarTestimonialSection } from '../../components/landing/star-testimonial-section.vue'
export { default as LandingTestimonialSection } from '../../components/landing/testimonial-section.vue'
export { default as MobileProxyApiAdmin } from '../../components/mobile-proxy-api/admin.vue'
export { default as MobileProxyApiCardList } from '../../components/mobile-proxy-api/card-list.vue'
export { default as MobileProxyApiDynamicPricing } from '../../components/mobile-proxy-api/dynamic-pricing.vue'
export { default as MobileProxyApiFlatratePricing } from '../../components/mobile-proxy-api/flatrate-pricing.vue'
export { default as MobileProxyApiTable } from '../../components/mobile-proxy-api/table.vue'
export { default as ModalDelete } from '../../components/modal/delete.vue'
export { default as ModalNotification } from '../../components/modal/notification.vue'
export { default as SettingsAccountView } from '../../components/settings/account-view.vue'
export { default as NavigationBreadcrumbs } from '../../components/navigation/breadcrumbs.vue'
export { default as NavigationTopBar } from '../../components/navigation/top-bar.vue'
export { default as InstagramAccountsCardList } from '../../components/instagram/accounts/card-list.vue'
export { default as InstagramAccountsDetails } from '../../components/instagram/accounts/details.vue'
export { default as InstagramAccountsForm } from '../../components/instagram/accounts/form.vue'
export { default as InstagramAccountsProgressBar } from '../../components/instagram/accounts/progress-bar.vue'
export { default as InstagramAccountsSettingsForm } from '../../components/instagram/accounts/settings-form.vue'
export { default as InstagramAccountsSort } from '../../components/instagram/accounts/sort.vue'
export { default as InstagramAccountsSubscriptionTable } from '../../components/instagram/accounts/subscription-table.vue'
export { default as InstagramAccountsTable } from '../../components/instagram/accounts/table.vue'
export { default as InstagramAccountsView } from '../../components/instagram/accounts/view.vue'
export { default as InstagramModalAddFollowers } from '../../components/instagram/modal/add-followers.vue'
export { default as InstagramModalCancelSubscription } from '../../components/instagram/modal/cancel-subscription.vue'
export { default as InstagramModalOrderDetails } from '../../components/instagram/modal/order-details.vue'
export { default as InstagramModalRemoveFollowers } from '../../components/instagram/modal/remove-followers.vue'
export { default as InstagramModalRestartSubscription } from '../../components/instagram/modal/restart-subscription.vue'
export { default as InstagramOrdersCardList } from '../../components/instagram/orders/card-list.vue'
export { default as InstagramOrdersDripFeed } from '../../components/instagram/orders/drip-feed.vue'
export { default as InstagramOrdersEmojiSelection } from '../../components/instagram/orders/emoji-selection.vue'
export { default as InstagramOrdersOrderTypesSelection } from '../../components/instagram/orders/order-types-selection.vue'
export { default as InstagramOrdersTable } from '../../components/instagram/orders/table.vue'
export { default as AdminModalAddUser } from '../../components/admin/modal/add-user.vue'
export { default as AdminModalUserDiscounts } from '../../components/admin/modal/user-discounts.vue'
export { default as AdminSettingsFields } from '../../components/admin/settings/fields.vue'
export { default as MobileProxyApiModalAddRemovePorts } from '../../components/mobile-proxy-api/modal/add-remove-ports.vue'
export { default as MobileProxyApiModalFlatratePricing } from '../../components/mobile-proxy-api/modal/flatrate-pricing.vue'
export { default as AdminUsersCardList } from '../../components/admin/users/card-list.vue'
export { default as AdminUsersForm } from '../../components/admin/users/form.vue'
export { default as AdminUsersTable } from '../../components/admin/users/table.vue'
export { default as MobileProxyApiReportingCardList } from '../../components/mobile-proxy-api/reporting/card-list.vue'
export { default as MobileProxyApiReportingTable } from '../../components/mobile-proxy-api/reporting/table.vue'
export { default as MobileProxyApiSubscriptionActive } from '../../components/mobile-proxy-api/subscription/active.vue'
export { default as MobileProxyApiSubscriptionInactive } from '../../components/mobile-proxy-api/subscription/inactive.vue'
export { default as MobileProxyApiSubscriptionPricing } from '../../components/mobile-proxy-api/subscription/pricing.vue'
export { default as SettingsBlogsForm } from '../../components/settings/blogs/form.vue'
export { default as SettingsBlogsList } from '../../components/settings/blogs/list.vue'
export { default as SettingsModalAddCredits } from '../../components/settings/modal/add-credits.vue'
export { default as SettingsModalAddTransaction } from '../../components/settings/modal/add-transaction.vue'
export { default as SettingsModalBitcoin } from '../../components/settings/modal/bitcoin.vue'
export { default as SettingsTransactionsAddCreditsForm } from '../../components/settings/transactions/add-credits-form.vue'
export { default as SettingsTransactionsAddManualForm } from '../../components/settings/transactions/add-manual-form.vue'
export { default as SettingsTransactionsCardList } from '../../components/settings/transactions/card-list.vue'
export { default as SettingsTransactionsFilter } from '../../components/settings/transactions/filter.vue'
export { default as SettingsTransactionsTable } from '../../components/settings/transactions/table.vue'
export { default as InstagramOrdersModalCancelRefund } from '../../components/instagram/orders/modal/cancel-refund.vue'
export { default as InstagramOrdersModalEdit } from '../../components/instagram/orders/modal/edit.vue'
export { default as SettingsBlogsModalCategories } from '../../components/settings/blogs/modal/categories.vue'
export { default as SettingsBlogsCategoriesConfig } from '../../components/settings/blogs/categories/config.vue'
export { default as SettingsBlogsCategoriesForm } from '../../components/settings/blogs/categories/form.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
